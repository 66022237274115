import React from 'react';

const Footer: React.FC = () => {
  return (
    <footer className="flex flex-wrap gap-2 justify-center items-center px-20 py-4 w-full text-lg tracking-widest text-center text-yellow-400 bg-amber-600 max-md:px-5 max-md:max-w-full">
      <div className="self-stretch my-auto bg-blend-normal">
        2024 Khaby Lame COINS | All Rights Reserved
      </div>
      <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/1f19513bad9f264b4fee1ac75ba44b078838b0a25c273c1e8d3dd6e82a884144?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64" alt="" className="object-contain shrink-0 self-stretch my-auto aspect-[1.12] w-[9px]" />
    </footer>
  );
};

export default Footer;