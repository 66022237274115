import React from 'react';
import './App.css';
import KhabyLameMain from './components/KhabyLameMain';

function App() {
  return (
    <KhabyLameMain />
  );
}

export default App;
