import React from 'react';

const Header: React.FC = () => {
  return (
    <header className="relative flex-col mt-14 w-full max-md:mt-10 max-md:max-w-full hidden">
      {/* Header content */}
    </header>
  );
};

export default Header;