import React from "react";

const HowToBuySection = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <section
      ref={ref}
      className="flex relative z-0 flex-col items-start px-24 py-32 w-full text-6xl leading-tight text-center text-yellow-400 uppercase max-md:px-5 max-md:py-44 max-md:pb-60 max-md:max-w-full max-md:text-4xl"
    >
      <h2 className="z-0 border-black border-solid border-[5px] text-zinc-500 border-transparent mx-auto max-md:max-w-full max-md:text-4xl text-ageer-black">
        How to buy
      </h2>
      <div className="flex w-full mt-8 justify-center flex-wrap max-md:gap-2">
        <img
          src="/images/how-to-buy-1.png"
          className="basis-1/2 hover-slideDown"
          alt="how-to-buy-1"
        />
        <img
          src="/images/how-to-buy-2.png"
          className="basis-1/2 hover-slideDown"
          alt="how-to-buy-2"
        />
        <img
          src="/images/how-to-buy-3.png"
          className="basis-1/2 hover-slideDown"
          alt="how-to-buy-3"
        />
        <img
          src="/images/how-to-buy-4.png"
          className="basis-1/2 hover-slideDown"
          alt="how-to-buy-4"
        />
      </div>
      {/* <img loading="lazy" src="https://cdn.builder.io/api/v1/image/assets/TEMP/9eef5c8e67763f41d2087b3f97d3ba82813e57ce019b186103ef9c9579f59dc8?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64" alt="How to buy steps" className="object-contain z-0 self-stretch mt-12 w-full aspect-[0.95] max-md:mt-10 max-md:max-w-full" /> */}
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/1a050d048b9165f6f9f70f1a12e920368125fa97598166c14569316d88cd2d64?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
        alt=""
        className="object-contain absolute right-0 z-0 max-w-full aspect-[0.64] bottom-[-83px] h-[344px]"
      />
      <img
        loading="lazy"
        src="https://cdn.builder.io/api/v1/image/assets/TEMP/10b6fb4a6e4fe35590f841c72bd2322f0d12d8882dbd7efe0d438fd91e8d6d3f?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
        alt=""
        className="object-contain absolute top-[-60px] left-0 z-0 max-w-full aspect-[0.93] h-[296px]"
      />
    </section>
  );
});

export default HowToBuySection;
