import React from "react";

const AboutSection = React.forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <section ref={ref} className="flex relative flex-col mt-14 w-full max-md:mt-10 max-md:max-w-full">
      <div className="flex overflow-hidden z-0 flex-col justify-center px-16 py-36 w-full uppercase max-md:px-5 max-md:py-24 max-md:pt-36 max-md:max-w-full">
        <div className="flex relative flex-col flex-wrap gap-8 justify-center items-start px-16 py-12 -mb-8 w-full rounded min-h-[495px] max-w-[1260px] mx-auto max-md:px-5 max-md:mb-2.5 max-md:max-w-full">
          <img
            loading="lazy"
            src="https://cdn.builder.io/api/v1/image/assets/TEMP/dcdc929b7ee3f024082424dc1e8c1ca9f610ecde31caa1a5278313edb6e18a99?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
            alt=""
            className="object-contain absolute max-w-full aspect-square h-[227px] left-[30px] top-[-160px] w-[227px] z-10"
          />
          <img
            loading="lazy"
            src="/images/about-bg.png"
            alt=""
            className="object-cover absolute inset-0 size-full border-[7px] border-black rounded-[12px]"
          />
          <div className="flex relative flex-wrap gap-4 justify-center items-center min-w-[240px] max-md:max-w-full">
            <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[706px] max-md:max-w-full">
              <h2 className="hover-slideDown gap-2.5 self-stretch p-2.5 w-full text-5xl leading-tight text-center text-yellow-400 border-transparent border-black border-solid border-[5px] max-md:max-w-full max-md:text-4xl">
                About Khaby Lame
              </h2>
              <p className="hover-slideDown gap-2.5 self-stretch py-2.5 pl-2.5 mt-12 max-w-full text-2xl leading-7 text-white w-[723px] max-md:mt-10 max-md:max-w-full">
                Believe in keeping things simple, just like the viral internet
                sensation himself! Inspired by Khabane Lame's iconic gestures
                and no-nonsense approach, our meme coin is designed to make
                trading easy and enjoyable. With Khabane Lame Coin, you don't
                need to overcomplicate your investments. Embrace simplicity,
                enjoy trading, and watch your profits grow.
              </p>
            </div>
            <img
              loading="lazy"
              src="https://cdn.builder.io/api/v1/image/assets/TEMP/6680c8305a26a4b9c8382bb6ef777994f0febfab162ab3243b908c1e4e5d5ac1?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
              alt="Khaby Lame"
              className="hover-slideDown object-contain self-stretch my-auto aspect-[1.02] min-w-[240px] w-[409px]"
            />
          </div>
        </div>
      </div>
    </section>
  );
});

export default AboutSection;
