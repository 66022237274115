import React from 'react';

interface TokenInfoCardProps {
  title: string;
  value: string;
  icon: string;
}

const TokenInfoCard: React.FC<TokenInfoCardProps> = ({ title, value, icon }) => {
  return (
    <div className="flex grow shrink gap-10 justify-between items-center self-stretch px-8 py-4 my-auto border-amber-600 border-solid bg-yellow-950 border-[5px] min-w-[240px] w-[265px] max-md:px-5 hover-slideDown">
      <div className="flex flex-col items-start self-stretch my-auto">
        <div className="text-4xl tracking-wide">{title}</div>
        <div className="mt-2 text-2xl">{value}</div>
      </div>
      <img loading="lazy" src={icon} alt={`${title} icon`} className="object-contain shrink-0 self-stretch my-auto rounded-3xl aspect-[0.85] w-[52px]" />
    </div>
  );
};

export default TokenInfoCard;