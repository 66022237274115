import React from "react";
import TokenInfoCard from "./TokenInfoCard";

const TokenomicsSection = React.forwardRef<HTMLDivElement>((props, ref) => {
  const tokenInfo = [
    {
      title: "Supply",
      value: "$10,000,000,000",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b75bb86c1acb9a7436fb622a1d6c2ed083849bab8eb20fac6733b68ff7e46179?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64",
    },
    {
      title: "Liquidity",
      value: "Burnt",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/35d9b0105095ad46088642799c359cd8c259ad6f1bf968d87e7d516a7a6b1d3a?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64",
    },
    {
      title: "Tax",
      value: "%0",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/b60ae7dbd206657fc63955a854d5dd564c8fb19699405b95c5f23777b2620586?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64",
    },
    {
      title: "Mint",
      value: "Revoked",
      icon: "https://cdn.builder.io/api/v1/image/assets/TEMP/a7d41823d04bc78d9293ee53ca2a008277402d14310405624a919f3ef156e1e4?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64",
    },
  ];

  return (
    <section
      ref={ref}
      className="flex relative z-0 flex-col justify-between items-center px-24 py-20 w-full whitespace-nowrap min-h-[585px] max-md:px-5 max-md:max-w-full"
    >
      <img
        loading="lazy"
        src="/images/tokenomic-bg.png"
        alt=""
        className="object-cover absolute inset-0 size-full"
      />
      <div className="flex relative flex-wrap gap-10 justify-between items-center self-stretch my-auto min-w-[240px] w-[1260px] mx-auto max-md:w-auto max-md:gap-16">
        <div className="flex flex-col self-stretch my-auto min-w-[240px] w-[705px] max-md:w-auto">
          <h2 className="text-6xl leading-tight text-center text-yellow-400 uppercase border-black border-solid border-transparent border-[5px] max-md:max-w-full max-md:text-4xl text-ageer-black">
            Tokenomics
          </h2>
          <div className="flex flex-col mt-20 w-full leading-none text-center text-white max-md:mt-10 max-md:max-w-full">
            <div className="flex flex-wrap gap-10 items-center w-full max-md:max-w-full">
              {tokenInfo.slice(0, 2).map((info, index) => (
                <TokenInfoCard key={index} {...info} />
              ))}
            </div>
            <div className="flex flex-wrap gap-10 items-center mt-11 w-full max-md:mt-10 max-md:max-w-full">
              {tokenInfo.slice(2).map((info, index) => (
                <TokenInfoCard key={index} {...info} />
              ))}
            </div>
          </div>
        </div>
        <img
          loading="lazy"
          src="https://cdn.builder.io/api/v1/image/assets/TEMP/5fc8fbe3f41e54ec49cdaf8c844046784e4a7df5bebc314d1ed36d4b0ca73f5f?placeholderIfAbsent=true&apiKey=f9f711ac72684fe79caf31b99cec2d64"
          alt="Khaby Lame coin"
          className="object-contain self-stretch my-auto aspect-square min-w-[240px] rounded-[10000px] shadow-[0px_0px_24px_rgba(255,245,0,1)] w-[425px] max-md:max-w-full hover-slideDown"
        />
      </div>
    </section>
  );
});

export default TokenomicsSection;
